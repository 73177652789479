import React from "react"

const Row = ({ children }) => {
    return (
        <div className="row block flex justify-content gap-4 flex-col lg:flex-row my-3">
            {children}
        </div>
    )
}

const Col = ({ children }) => {
    return (
        <div className="flex-1">
            {children}
        </div>
    )
}

export { Row, Col }